import React from "react";
import Layout from "../components/layout-default";
import Hero from "../components/hero";
import Breadcrumbs from "../components/breadcrumbs";
import LosMuetros from "../images/Los_Muertos.png";
import LosMuetros2 from "../images/Muertos.png";
import Banda from "../images/BandaClassLatest.jpg";
import GoldenGala from "../images/GoldenGalaSocial.png";
import rfKidsShow from "../images/rfKidsShowcase.jpg";
import rfMambo from "../images/rfMamb0Shines.jpg";
import rfShowCase from "../images/rfShowcase2024.jpg";
import nextIcon from "../images/icons8-next-50.png";

import { Link } from "gatsby";

const crumbs = [
  {
    label: "Home",
    link: "",
  },
  {
    label: "News",
    link: "news",
  },
];

class News extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImage: LosMuetros2,
    };
  }

  handleImageSwitch = () => {
    this.setState((prevState) => ({
      currentImage:
        prevState.currentImage === LosMuetros2 ? LosMuetros : LosMuetros2,
    }));
  };

  render() {
    return (
      <Layout
        title="News | RF Dance"
        pathname="news/"
        noHero="no-hero"
        className="news_page"
      >
        <Hero titleRed={`RF`} titleWhite={`News`} />

        <div className="content-block">
          <div className="container">
            <Breadcrumbs crumbs={crumbs} />
            <div className="news_area">
              {/* English */}
              <h3>English:</h3>
              <div className="english-event">
                <div className="news_item">
                  <div className="image_container">
                    <img
                      src={Banda}
                      alt="Banda Class"
                      className="event-image"
                    />
                  </div>

                  <div className="news_content">
                    <h3 className="news_heading">Banda Class </h3>
                    <div className="news_content_area">
                      <p>
                        <b>Get Ready to Learn some Banda!</b> Join us for a fun
                        Banda Class led by Christopher & Destany on Friday,
                        November 15th at 9:00 pm at RF Dance Center. Secure your
                        pre-sale spot for $20 (Non-Refundable), or pay $30 at
                        the door. RF Members attend for free. Don't just sit
                        around at parties when the banda is playing! No partner
                        is necessary, and all levels are welcome. Stick around
                        after the class for an extra hour of practice and social
                        dancing to Cumbia and Banda rhythms! Secure your spot
                        and join the fiesta! 🇲🇽
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sLevel=4&sView=day&sLoc=1&sTrn=100000150&date=11/15/24">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="news_item">
                  <div className="image_container">
                    <img
                      src={GoldenGala}
                      alt="GoldenGala"
                      className="event-image"
                    />
                  </div>

                  <div className="news_content">
                    <h3 className="news_heading">RF Golden Gala Social </h3>
                    <div className="news_content_area">
                      <p>
                        <b> Get Ready for a Night of Glamour and Dance!</b>{" "}
                        <br />
                        Join us for the <b>RF Golden Gala Social</b> on{" "}
                        <b>Friday, November 22nd</b>, from{" "}
                        <b>9:00 PM to 1:00 AM </b>at <b>RF Dance Center</b>,
                        located at <b>1517 N. Main St, Santa Ana, CA.</b>
                        <h4>Agenda:</h4>
                        <ul>
                          <li>
                            <b>9:00 PM:</b> Kick off the night with an exciting
                            Banda class led by <b>Christopher & Destany!</b>
                          </li>
                          <li>
                            <b>Social Dancing:</b> Enjoy music from{" "}
                            <b>DJ Real Deal</b> and <b>DJ Noe</b> until 1:00 AM!
                          </li>
                        </ul>
                        <h4>Tickets:</h4>
                        <ul>
                          <li>
                            <b>Pre-sale:</b> $25 (non-refundable)
                          </li>
                          <li>
                            <b>At the Door:</b> $35
                          </li>
                        </ul>
                        <b>RF Members:</b> Enjoy free entry from{" "}
                        <b>9:00 PM to 1:00 PM</b> when you pre-register by
                        Friday! If not signed up, the door price applies.
                        <br />
                        <br />
                        <b>Dress Code:</b> Wear gold and come ready to dance the
                        night away!
                        <br />
                        <br />
                        <b>
                          Secure your spot early and let’s celebrate together!
                        </b>
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=47&sVT=64&sLevel=8&sView=day&sLoc=1&sTrn=100000131&date=11/22/24">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="news_item">
                  <div className="image_container">
                    <img
                      src={rfKidsShow}
                      alt="Rf Kids Showcase"
                      className="event-image"
                    />
                  </div>

                  <div className="news_content">
                    <h3 className="news_heading">
                      RF Kids Showcase Challenge{" "}
                    </h3>
                    <div className="news_content_area">
                      <p>
                        Don't miss an exciting chance to see your child shine at
                        the RF Showcase! Kids will practice and perform a Latin
                        Fusion routine, building confidence and having fun.
                        Rehearsals are on November 7th, 14th, and 21st from 7:00
                        pm to 8:00 pm, leading up to the showcase on November
                        24th, with a rehearsal before the show. Rehearsals will
                        be at RF Dance Center, and the performance will take
                        place at the Bill Medley Auditorium. RF Members
                        participate for FREE, and Non-Members can join for $60.
                        Don’t miss out—sign your child up today!
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=58&sVT=88&sLevel=4&sView=day&sLoc=1&sTrn=100000159&date=11/07/24">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://www.eventbrite.com/e/rf-showcase-2024-tickets-1045351673997">
                      <img src={rfShowCase} alt="  RF Showcase 2024 " />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">RF Showcase 2024</h3>
                    <div className="news_content_area">
                      <p>
                        Join us for an exciting evening of dance at RF Showcase
                        2024, featuring performances by RF teams, solos, duets,
                        and friends. Celebrate the hard work, passion, and
                        creativity of the dancers as they take the stage to
                        deliver.
                        <br />
                        Date: November 24 <br />
                        Doors Open: 2:00 pm | Show Starts: 2:30 pm <br />
                        Location: 520 W Walnut St, Santa Ana, CA 92701 (Bill
                        Medley Auditorium)
                        <ul>
                          <h4>Ticket Prices:</h4>
                          <li>
                            <p>
                              October 15th – November 3rd: $5 (Non-Refundable)
                            </p>
                          </li>
                          <li>
                            <p>November 4th – 10th: $10 (Non-Refundable)</p>
                          </li>
                          <li>
                            <p>November 11th – 17th: $20 (Non-Refundable)</p>
                          </li>
                          <li>
                            <p>November 18th – 23rd: $30 (Non-Refundable)</p>
                          </li>
                          <li>
                            <p>Door Price: $40</p>
                          </li>
                          <h4>VIP Seating:</h4>
                          <li>
                            <p>
                              $20 on top of the ticket price (Non-Refundable)
                            </p>
                          </li>
                        </ul>
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://www.eventbrite.com/e/rf-showcase-2024-tickets-1045351673997">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="news_item">
                  <div className="image_container">
                    <img src={rfMambo} alt="rfMambo" className="event-image" />
                  </div>

                  <div className="news_content">
                    <h3 className="news_heading">RF Mambo Shines Challenge</h3>
                    <div className="news_content_area">
                      <p>
                        Join the RF Mambo Shines Challenge, led by Omar Muñoz,
                        and elevate your Salsa skills! Rehearsals are on
                        November 10th and 17th from 12:00 pm to 2:30 pm at RF
                        Dance Center. Showcase will take place on November 24th
                        at the Bill Medley Auditorium, where you'll show off
                        your hard work and shine on stage. RF Members can join
                        for $50, and Non-Members for $60. Don't miss out—sign up
                        today
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=45&sVT=87&sLevel=4&sView=day&sLoc=1&sTrn=100000051&date=11/10/24">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              {/* Spanish */}
              <h3>Spanish:</h3>
              <div className="spanish-event">
                <div className="news_item">
                  <div className="image_container">
                    <img
                      src={Banda}
                      alt="Banda Class"
                      className="event-image"
                    />
                  </div>

                  <div className="news_content">
                    <h3 className="news_heading">Clase de Banda </h3>
                    <div className="news_content_area">
                      <p>
                        <b> Prepárate para aprender Banda! </b>Únete a una
                        divertida clase de Banda dirigida por Christopher y
                        Destany el viernes 15 de Noviembre a las 9:00 p.m. en RF
                        Dance Center. Asegura tu lugar en preventa por $20 (no
                        reembolsable), o paga $30 en la puerta. ¡Los miembros de
                        RF asisten gratis! No te quedes sentado en las fiestas
                        cuando suena la banda. ¡No necesitas pareja y todos los
                        niveles son bienvenidos! Quédate después de la clase
                        para una hora extra de práctica y baile social al ritmo
                        de Cumbia y Banda. ¡Asegura tu lugar y únete a la
                        fiesta! 🇲🇽
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sLevel=4&sView=day&sLoc=1&sTrn=100000150&date=11/15/24">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="news_item">
                  <div className="image_container">
                    <img
                      src={GoldenGala}
                      alt="GoldenGala"
                      className="event-image"
                    />
                  </div>

                  <div className="news_content">
                    <h3 className="news_heading">RF Golden Gala Social </h3>
                    <div className="news_content_area">
                      <p>
                        <b> Prepárate para una Noche de Glamour y Baile!</b>{" "}
                        <br />
                        Únete a nosotros para el <b>
                          RF Golden Gala Social
                        </b> el <b>viernes 22 de noviembre,</b>, de{" "}
                        <b>9:00 PM a 1:00 AM </b>en el <b>RF Dance Center</b>,
                        located at <b>1517 N. Main St, Santa Ana, CA.</b>
                        <h4>Agenda:</h4>
                        <ul>
                          <li>
                            <b>9:00 PM:</b> ¡Comienza la noche con una
                            emocionante clase de Banda dirigida por Christopher
                            y Destany!
                          </li>
                          <li>
                            <b>Baile Social:</b> ¡Disfruta de la música de DJ
                            Real Deal y DJ Noe hasta la 1:00 AM!
                          </li>
                        </ul>
                        <h4>Boletos:</h4>
                        <ul>
                          <li>
                            <b>Pre-venta:</b> $25 (no reembolsable)
                          </li>
                          <li>
                            <b>En la puerta:</b> $35
                          </li>
                        </ul>
                        <b>Miembros de RF:</b> ¡Disfruten de entrada gratuita de
                        <b>9:00 PM a 11:00 PM</b> si se pre-registran antes del
                        viernes! Si no están inscritos, se aplicará el precio de
                        entrada.
                        <br />
                        <br />
                        <b>Código de Vestimenta:</b> ¡Usa oro y ven listo para
                        bailar toda la noche! ¡Asegura tu lugar pronto y
                        celebremos juntos!
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=47&sVT=64&sLevel=8&sView=day&sLoc=1&sTrn=100000131&date=11/22/24">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="news_item">
                  <div className="image_container">
                    <img
                      src={rfKidsShow}
                      alt="Rf Kids Showcase"
                      className="event-image"
                    />
                  </div>

                  <div className="news_content">
                    <h3 className="news_heading">
                      RF Kids Showcase Challenge{" "}
                    </h3>
                    <div className="news_content_area">
                      <p>
                        ¡No te pierdas la oportunidad emocionante de ver a tu
                        hijo brillar en el RF Showcase! Los niños practicarán y
                        presentarán una rutina de Fusión Latina, ganando
                        confianza y divirtiéndose. Los ensayos serán el 7, 14 y
                        21 de noviembre de 7:00 pm a 8:00 pm, preparándose para
                        la presentación el 24 de noviembre, con un ensayo antes
                        del show. Los ensayos serán en el RF Dance Center, y la
                        presentación se llevará a cabo en el Bill Medley
                        Auditorium. Los miembros de RF participan GRATIS, y los
                        no miembros pueden unirse por $60. ¡No te lo pierdas,
                        inscribe a tu hijo hoy!
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=58&sVT=88&sLevel=4&sView=day&sLoc=1&sTrn=100000159&date=11/07/24">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://www.eventbrite.com/e/rf-showcase-2024-tickets-1045351673997">
                      <img src={rfShowCase} alt="  RF Showcase 2024 " />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">RF Showcase 2024</h3>
                    <div className="news_content_area">
                      <p>
                        Únase a nosotros para una emocionante noche de baile en
                        el RF Showcase 2024, con presentaciones de los equipos
                        de RF, solos, duetos y amigos. Celebre el esfuerzo, la
                        pasión y la creatividad de los bailarines mientras suben
                        al escenario para deslumbrar
                        <br />
                        Fecha: 24 de noviembre <br />
                        Puertas Abren: 2:00 pm | El Show Comienza: 2:30 pm
                        <br />
                        Ubicación: 520 W Walnut St, Santa Ana, CA 92701 (Bill
                        Medley Auditorium)
                        <ul>
                          <h4>Precios de Boletos:</h4>
                          <li>
                            <p>
                              15 de octubre – 3 de noviembre: $5 (No
                              reembolsable)
                            </p>
                          </li>
                          <li>
                            <p>
                              4 de noviembre – 10 de noviembre: $10 (No
                              reembolsable)
                            </p>
                          </li>
                          <li>
                            <p>
                              11 de noviembre – 17 de noviembre: $20 (No
                              reembolsable)
                            </p>
                          </li>
                          <li>
                            <p>
                              18 de noviembre – 23 de noviembre: $30 (No
                              reembolsable)
                            </p>
                          </li>
                          <li>
                            <p>Precio en puerta: $40</p>
                          </li>
                          <h4>Asientos VIP:</h4>
                          <li>
                            <p>
                              $20 adicionales al precio del boleto (No
                              reembolsable)
                            </p>
                          </li>
                        </ul>
                        Adquiera sus boletos a tiempo y no se pierda esta
                        inolvidable velada llena de talento.
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://www.eventbrite.com/e/rf-showcase-2024-tickets-1045351673997">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="news_item">
                  <div className="image_container">
                    <img src={rfMambo} alt="rfMambo" className="event-image" />
                  </div>

                  <div className="news_content">
                    <h3 className="news_heading">RF Mambo Shines Challenge</h3>
                    <div className="news_content_area">
                      <p>
                        Únete al RF Mambo Shines Challenge, dirigido por Omar
                        Muñoz, y mejora tus habilidades en Salsa. Los ensayos
                        serán el 10 y 17 de noviembre de 12:00 pm a 2:30 pm en
                        RF Dance Center. El showcase se llevará a cabo el 24 de
                        noviembre en el Bill Medley Auditorium, donde podrás
                        mostrar todo tu esfuerzo y brillar en el escenario. Los
                        miembros de RF pueden participar por $50, y los no
                        miembros por $60. ¡No te lo pierdas, inscríbete hoy
                        mismo!
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=45&sVT=87&sLevel=4&sView=day&sLoc=1&sTrn=100000051&date=11/10/24">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default News;
